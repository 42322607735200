enum ErrorMessages {
  ERROR_SOMETHING_WENT_WRONG = "Something went wrong, please try again later",
  ERROR_QUOTE_EMAIL_DUPLICATED_PROFILE = "Email associated with different phone number",
  ERROR_QUOTE_PHONE_DUPLICATED_PROFILE = "Phone number associated with a different email",
  ERROR_WAIT_BEFORE_REQUESTING_NEW_CODE = "Please wait before requesting a new code",
  ERROR_VALIDATE_PHONE_NUMBER = "Invalid verfication code",
  PROFILE_VERIFY_CONFLICT_ERROR = "Profile with this email or phone number is already verified",
}

export default ErrorMessages;
