import axios, { AxiosError } from "axios";
import { POSITION, useToast } from "vue-toastification";
import ErrorMessages from "@/constants/errorsMessages";
import KlaviyoErrorEnum from "@/enums/KlaviyoEnum";

const toast = useToast();

export const handleError = (error: AxiosError): void => {
  let message = "There was an error submitting the form";
  const errorMsg = error?.response?.data?.message;
  const errorCode = error?.response?.data?.errors?.[0]?.code;

  switch (errorMsg) {
    case ErrorMessages.ERROR_QUOTE_EMAIL_DUPLICATED_PROFILE:
      message = ErrorMessages.ERROR_QUOTE_EMAIL_DUPLICATED_PROFILE;
      break;
    case ErrorMessages.PROFILE_VERIFY_CONFLICT_ERROR:
      message = ErrorMessages.PROFILE_VERIFY_CONFLICT_ERROR;
      break;
    case ErrorMessages.ERROR_WAIT_BEFORE_REQUESTING_NEW_CODE:
      message = ErrorMessages.ERROR_WAIT_BEFORE_REQUESTING_NEW_CODE;
      break;
  }

  if (errorCode === KlaviyoErrorEnum.DUPLICATE_PROFILE) {
    message = ErrorMessages.ERROR_QUOTE_PHONE_DUPLICATED_PROFILE;
  }

  toast.error(message, {
    position: POSITION.TOP_LEFT,
  });
};
