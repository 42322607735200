import { Ref } from "vue";
interface ReturnInterface {
  onTouchstart: (event: TouchEvent) => void;
  onTouchMove: (event: TouchEvent) => void;
  onTouchEnd: (event: TouchEvent) => void;
  onTouchCancel: () => void;
}
export const useScrollDragMobile = (
  elementRef: Ref<HTMLElement>
): ReturnInterface => {
  let isDown = false;
  let startX = 0;
  let startY = 0;
  let scrollLeft = 0;
  let scrollTop = 0;
  const onTouchstart = ({ touches }: TouchEvent) => {
    if (!elementRef.value) return;

    const touch = touches[0];
    const rect = elementRef.value.getBoundingClientRect();

    isDown = true;
    startX = touch.pageX - rect.left;
    startY = touch.pageY - rect.top;
    scrollLeft = elementRef.value.scrollLeft;
    scrollTop = elementRef.value.scrollTop;
  };

  const onTouchEnd = ({ changedTouches }: TouchEvent) => {
    if (!elementRef.value) return;

    isDown = false;
    const touch = changedTouches[0];
    const clickedElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    ) as HTMLElement;

    if (clickedElement) {
      clickedElement.click();
    }
  };

  const onTouchCancel = () => {
    isDown = false;
  };

  const onTouchMove = ({ touches }: TouchEvent) => {
    if (!isDown || !elementRef.value) return;

    const { pageX, pageY } = touches[0];
    const { left, top } = elementRef.value.getBoundingClientRect();

    const x = pageX - left;
    const y = pageY - top;

    const walkX = (x - startX) * 1;
    const walkY = (y - startY) * 1;

    elementRef.value.scrollLeft = scrollLeft - walkX;
    elementRef.value.scrollTop = scrollTop - walkY;
  };
  return {
    onTouchstart,
    onTouchMove,
    onTouchEnd,
    onTouchCancel,
  };
};
