enum LeadSourceEnum {
  /**
   *Automatic lead addition after form submission
   */
  ENQUIRY = "Form submission",

  /**
   *Add a lead manually
   */
  MANUALLY = "Created",

  /**
   *Add leads from uploaded xlsx file
   */
  IMPORT = "Import",

  /**
   *Automatic lead addition after waitlist form submission
   */
  WAITLIST = "Waitlist",

  /**
   *Lead that comes from Realestate.com.au
   */
  REA = "Realestate.com.au",

  /**
   *Lead created when a Initial Hold Request is made.
   */
  INITIAL_HOLD = "Initial Hold Request",
}

export default LeadSourceEnum;
